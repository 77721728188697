<template>
    <div class="">
        <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
            <div class="card-table-body ifon-add-title-block">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="caz-blocks-sarcho-title">
                            <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                                {{ $t('message.clients') }}
                            </div>
                            <div class="block-sarche">
                                <div class="header__search">
                                    <el-input
                                        :placeholder="$t('message.search')"
                                        prefix-icon="el-icon-search"
                                        :class="mode ? 'input__day' : 'input__night'"
                                        v-model="sarcheBlocks"
                                        size="small"
                                        round
                                        style="width: 100% !important"
                                    ></el-input>
                                </div>
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="12" class="flex-style text-right">
                        <crm-create-and-column-settings
                            :permission="$options.name"
                            :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                            @c-create="drawerCreate = true"
                            :columns="columns"
                            @c-change="updateColumn"
                        >
                        </crm-create-and-column-settings>
                    </el-col>
                </el-row>
            </div>
            <!-- end ifon-add-title-block -->

            <div class="card-table-header table-crm-smart">
                <table
                    class="table-my-code table-bordered"
                    v-loading="loadingData"
                    :class="mode ? 'table__myday' : 'table__mynight'"
                >
                    <thead>
                        <tr>
                            <th class="w50p" v-if="columns.id.show">
                                {{ columns.id.title }}
                            </th>

                            <th v-if="columns.clientTypes.show">
                                {{ columns.clientTypes.title }}
                            </th>

                            <th v-if="columns.company_id.show">
                                {{ columns.company_id.title }}
                            </th>

                            <th v-if="columns.full_name.show">
                                {{ columns.full_name.title }}
                            </th>

                            <th v-if="columns.date_of_birth.show">
                                {{ columns.date_of_birth.title }}
                            </th>

                            <th v-if="columns.phone.show">
                                {{ columns.phone.title }}
                            </th>

                            <th v-if="columns.email.show">
                                {{ columns.email.title }}
                            </th>

                            <th v-if="columns.passport_serial_number.show">
                                {{ columns.passport_serial_number.title }}
                            </th>

                            <th v-if="columns.passport_validity.show">
                                {{ columns.passport_validity.title }}
                            </th>

                            <th v-if="columns.type_id.show">
                                {{ columns.type_id.title }}
                            </th>

                            <th v-if="columns.actual_address.show">
                                {{ columns.actual_address.title }}
                            </th>

                            <th v-if="columns.inn.show">
                                {{ columns.inn.title }}
                            </th>

                            <th v-if="columns.gender_id.show">
                                {{ columns.gender_id.title }}
                            </th>

                            <th v-if="columns.country_id.show">
                                {{ columns.country_id.title }}
                            </th>

                            <th v-if="columns.city_id.show">
                                {{ columns.city_id.title }}
                            </th>

                            <th v-if="columns.settings.show">
                                {{ columns.settings.title }}
                            </th>
                        </tr>

                        <tr class="filter_sorche">
                            <th v-if="columns.id.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.id"
                                    :placeholder="columns.id.title"
                                    class="id_input"
                                ></el-input>
                            </th>

                            <th v-if="columns.clientTypes.show">
                                <select-client-by-client-type
                                    :size="'mini'"
                                    :placeholder="columns.clientTypes.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :id="filterForm.clientType_id"
                                    v-model="filterForm.clientType_id"
                                >
                                </select-client-by-client-type>
                            </th>

                            <th v-if="columns.company_id.show">
                                <select-company
                                    :size="'mini'"
                                    :placeholder="columns.company_id.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :id="filterForm.company_id"
                                    v-model="filterForm.company_id"
                                >
                                </select-company>
                            </th>

                            <th v-if="columns.full_name.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.full_name"
                                    :placeholder="columns.full_name.title"
                                >
                                </el-input>
                            </th>
                            <th v-if="columns.date_of_birth.show">
                                <el-date-picker
                                    :placeholder="columns.date_of_birth.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.date_of_birth"
                                    size="mini"
                                >
                                </el-date-picker>
                            </th>
                            <th v-if="columns.phone.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.phone"
                                    :placeholder="columns.phone.title"
                                >
                                </el-input>
                            </th>
                            <th v-if="columns.email.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    v-model="filterForm.email"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.email.title"
                                ></el-input>
                            </th>
                            <th v-if="columns.passport_serial_number.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.passport_serial_number"
                                    :placeholder="
                                        columns.passport_serial_number.title
                                    "
                                ></el-input>
                            </th>
                            <th v-if="columns.passport_validity.show">
                                <el-date-picker
                                    :placeholder="
                                        columns.passport_validity.title
                                    "
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.passport_validity"
                                    size="mini"
                                >
                                </el-date-picker>
                            </th>
                            <th v-if="columns.type_id.show">
                                <select-client-type
                                    :size="'mini'"
                                    :placeholder="columns.type_id.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :id="filterForm.type_id"
                                    v-model="filterForm.type_id"
                                >
                                </select-client-type>
                            </th>
                            <th v-if="columns.actual_address.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    v-model="filterForm.actual_address"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.actual_address.title"
                                ></el-input>
                            </th>
                            <th v-if="columns.inn.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    v-model="filterForm.inn"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.inn.title"
                                ></el-input>
                            </th>
                            <th v-if="columns.gender_id.show">
                                <select-client-gender
                                    :size="'mini'"
                                    :placeholder="columns.gender_id.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :id="filterForm.gender"
                                    v-model="filterForm.gender"
                                >
                                </select-client-gender>
                            </th>
                            <th v-if="columns.country_id.show">
                                <select-country
                                    :placeholder="columns.country_id.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.country_id"
                                ></select-country>
                            </th>

                            <th v-if="columns.city_id.show">
                                <select-city
                                    :placeholder="columns.city_id.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.city_id"
                                ></select-city>
                            </th>

                            <th
                                class="settinW"
                                v-if="columns.settings.show"
                            ></th>
                        </tr>
                    </thead>

                    <transition-group name="flip-list" tag="tbody">
                        <tr
                            v-for="client in list"
                            :key="client.id"
                            class="cursor-pointer"
                            @dblclick="getSelectedForShow(client)"
                        >
                            <td v-if="columns.id.show">{{ client.id }}</td>
                            <td v-if="columns.clientTypes.show">
                                <div
                                    v-for="type in client.clientTypes"
                                    :key="type.id"
                                >
                                    {{ type ? type.name : "" }},
                                </div>
                            </td>
                            <td v-if="columns.company_id.show">
                                {{ client.company ? client.company.name : "" }}
                            </td>
                            <td v-if="columns.full_name.show">
                                {{ client.full_name }}
                            </td>
                            <td v-if="columns.date_of_birth.show">
                                {{ client.date_of_birth }}
                            </td>
                            <td v-if="columns.phone.show">
                                {{ client.phone }}
                            </td>
                            <td v-if="columns.email.show">
                                {{ client.email }}
                            </td>
                            <td v-if="columns.passport_serial_number.show">
                                {{ client.passport_serial_number }}
                            </td>
                            <td v-if="columns.passport_validity.show">
                                {{ client.passport_validity }}
                            </td>
                            <td v-if="columns.type_id.show">
                                {{ client.type ? client.type.name : "" }}
                            </td>

                            <td v-if="columns.actual_address.show">
                                {{ client.actual_address }}
                            </td>
                            <td v-if="columns.inn.show">
                                {{ client.inn }}
                            </td>
                            <td v-if="columns.gender_id.show">
                                {{ client.gender ? client.gender.name : "" }}
                            </td>
                            <td v-if="columns.country_id.show">
                                {{ client.country ? client.country.name : "" }}
                            </td>

                            <td v-if="columns.city_id.show">
                                {{ client.city ? client.city.name : "" }}
                            </td>

                            <td
                                v-if="columns.settings.show"
                                class="settings-td"
                            >
                                <crm-settings
                                    :name="$options.name"
                                    :model="client"
                                    :actions="actions"
                                    :permissionShow="'companies.update'"
                                    :permissionDestroy="'companies.delete'"
                                    @edit="edit"
                                    @delete="destroy"
                                ></crm-settings>
                            </td>
                        </tr>
                    </transition-group>
                </table>
                <div class="my___pagination">
                    <crm-pagination
                        @c-change="updatePagination"
                        :class="mode ? 'pagination__day' : 'pagination__night'"
                        :pagination="pagination"
                    ></crm-pagination>
                    <!-- <Pagination /> -->
                </div>
            </div>

            <div class="app-modal app-modal__full modal-color-bg">
                <el-drawer
                    :with-header="false"
                    :visible.sync="drawerCreate"
                    ref="drawerCreate"
                    size="70%"
                    class="body_scroll_70" 
                    @opened="drawerOpened('drawerCreateChild')"
                    @closed="drawerClosed('drawerCreateChild')"
                >
                    <div>
                        <crm-create
                            ref="drawerCreateChild"
                            drawer="drawerCreate"
                        >
                        </crm-create>
                    </div>
                </el-drawer>

                <el-drawer
                    :with-header="false"
                    :visible.sync="drawerUpdate"
                    size="70%"
                    ref="drawerUpdate"
                    class="body_scroll_70" 
                    @opened="drawerOpened('drawerUpdateChild')"
                    @closed="drawerClosed('drawerUpdateChild')"
                >
                    <crm-update
                        :selectedItem="selectedItem"
                        ref="drawerUpdateChild"
                        drawer="drawerUpdate"
                    ></crm-update>
                </el-drawer>

                <el-drawer
                    :with-header="false"
                    :visible.sync="crmShow"
                    size="80%"
                    :append-to-body="true"
                    class="my-bgd body_scroll_80"
                    ref="drawerShow"
                    @opened="drawerOpened('drawerShowChild')"
                    @closed="drawerClosed('drawerShowChild')"
                >
                    <crm-show
                        :selectedItem="selectedItem"
                        ref="drawerShowChild"
                        drawer="drawerShow">
                    
                    </crm-show>
                </el-drawer>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import Pagination from "@/components/el-pagination";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import CrmShow from "./components/show.vue";
import selectCompany from "@/components/inventory/select-company";
import selectClientType from "@/components/inventory/select-client-type";
import selectClientByClientType from "@/components/inventory/select-client-by-client-type";
import selectClientGender from "@/components/inventory/select-client-gender";
import selectCountry from "@/components/inventory/select-country";
import selectCity from "@/components/inventory/select-city";
import list from "@/utils/mixins/list";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "clients",
    mixins: [list],

    components: {
        CrmCreate,
        CrmUpdate,
        Pagination,
        selectCompany,
        selectClientType,
        selectClientGender,
        selectClientByClientType,
        selectCountry,
        selectCity,
        CrmShow,
    },

    data() {
        return {
            appModal: false,
            crmShow: false,
            sarcheBlocks: "",
        };
    },
    computed: {
        ...mapGetters({
            list: "clients/list",
            columns: "clients/columns",
            pagination: "clients/pagination",
            filter: "clients/filter",
            sort: "clients/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "clients/index",
            setPagination: "clients/setPagination",
            updateSort: "clients/updateSort",
            updateFilter: "clients/updateFilter",
            updateColumn: "clients/updateColumn",
            updatePagination: "clients/updatePagination",
            show: "clients/show",
            empty: "clients/empty",
            delete: "clients/destroy",
            refreshData: "clients/refreshData",
        }),
        getSelectedForShow(client){
            this.selectedItem = client;
            this.crmShow = true;
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("clients/EMPTY_LIST");
        next();
    },
};
</script>

